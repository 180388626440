import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import _isNil from 'lodash/isNil';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { UIProvider } from 'ui';

import App from './App';
import { BannerBox } from './components/BannerBox';
import { AuthProvider } from './components/authentication';
import { FallbackContainer } from './main.styled';
import { ApolloClientProvider } from './provider/ApolloClientProvider';
import { extractRegion } from './utils/common';
import { envMap } from './utils/constant';

const queryClient = new QueryClient();

const SENTRY_DSN = envMap.VITE_SENTRY_DSN;
const SENTRY_RELEASE_TAG = envMap.VITE_RELEASE_TAG;
const SENTRY_REGION = extractRegion();

if (
  envMap.VITE_ENVIRONMENT === 'production' &&
  !_isNil(SENTRY_DSN) &&
  !_isNil(SENTRY_DSN)
) {
  Sentry.init({
    release: SENTRY_RELEASE_TAG ?? '',
    environment:
      SENTRY_REGION === ''
        ? envMap.VITE_ENVIRONMENT
        : `${envMap.VITE_ENVIRONMENT}-${SENTRY_REGION}`,
    dsn: SENTRY_DSN,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function FallbackComponent() {
  return (
    <FallbackContainer>
      <BannerBox
        title="Error"
        subTitle="Sorry for the inconvenience. Please try again later."
        bannerImage="error.svg"
        buttonProps={{
          buttonUrl: '/home',
          buttonText: 'Go to Home',
          buttonTarget: '_self',
        }}
      />
    </FallbackContainer>
  );
}

const myFallback = <FallbackComponent />;

const root = (
  <Sentry.ErrorBoundary fallback={myFallback}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ApolloClientProvider>
            <UIProvider>
              <App />
            </UIProvider>
          </ApolloClientProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  root
);
