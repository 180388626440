import { gql } from '@apollo/client';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { RuleCronScheduleModel } from '../pages/Rules/components/SimpleRule/models';
import {
  ControlItemsType,
  DelayNodeFormAction,
} from '../pages/Workflow/models/models';
import type { Role } from '../pages/Workspace/component/types';
import { CAMEL_CASE_REGEX, SPACE_REGEX } from './regex';

export const Environment = Object.freeze({
  PRODUCTION: 'production',
  STAGING: 'staging',
});

export const envMap: Record<string, string> = {
  VITE_DEPLOYMENT_TYPE: import.meta.env.VITE_DEPLOYMENT_TYPE,
  VITE_ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  VITE_SIGNUP_FORM_ENABLED: import.meta.env.VITE_SIGNUP_FORM_ENABLED,
  VITE_ENABLE_GOOGLE_LOGIN: import.meta.env.VITE_ENABLE_GOOGLE_LOGIN,
  VITE_ENABLE_MS_LOGIN: import.meta.env.VITE_ENABLE_MS_LOGIN,
  VITE_GOOGLE_CLIENT_ID: import.meta.env.VITE_GOOGLE_CLIENT_ID,
  VITE_MICROSOFT_CLIENT_ID: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
  VITE_MICROSOFT_TENANT_ID: import.meta.env.VITE_MICROSOFT_TENANT_ID,
  VITE_GA_MID: import.meta.env.VITE_GA_MID,
  VITE_CLARITY_ID: import.meta.env.VITE_CLARITY_ID,
  VITE_GTM_ID: import.meta.env.VITE_GTM_ID,
  VITE_IP_INFO_KEY: import.meta.env.VITE_IP_INFO_KEY,
  VITE_RECAPTCHA_SITE_KEY: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
  VITE_TANDC_URL: import.meta.env.VITE_TANDC_URL,
  VITE_PP_URL: import.meta.env.VITE_PP_URL,
  VITE_COOKIE_CONSENT_ENABLED: import.meta.env.VITE_COOKIE_CONSENT_ENABLED,
  VITE_HIDE_OPTIONAL_ATTRIBUTES: import.meta.env.VITE_HIDE_OPTIONAL_ATTRIBUTES,
  VITE_API_HOST: import.meta.env.VITE_API_HOST,
  VITE_WITH_CREDENTIALS: import.meta.env.VITE_WITH_CREDENTIALS,
  VITE_GRAPHQL_URL: import.meta.env.VITE_GRAPHQL_URL,
  VITE_AUTH_CONFIG_ENABLED: import.meta.env.VITE_AUTH_CONFIG_ENABLED,
  VITE_ENABLE_EVENT_API: import.meta.env.VITE_ENABLE_EVENT_API,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  VITE_RELEASE_TAG: import.meta.env.VITE_RELEASE_TAG,
  VITE_API_FEATURE_FLAG: import.meta.env.VITE_API_FEATURE_FLAG,
  VITE_API_HOME_PAGE: import.meta.env.VITE_API_HOME_PAGE,
  VITE_API_STATE_UPDATE: import.meta.env.VITE_API_STATE_UPDATE,
  VITE_API_GET_STATE: import.meta.env.VITE_API_GET_STATE,
  VITE_ADMIN_EMAIL_CONFIG: import.meta.env.VITE_ADMIN_EMAIL_CONFIG,
  VITE_ASSETS_URL: import.meta.env.VITE_ASSETS_URL,
  VITE_EDITOR_URL: import.meta.env.VITE_EDITOR_URL,
  VITE_HELM_CHARTS_URL: import.meta.env.VITE_HELM_CHARTS_URL,
  VITE_API_PREMIUM_FEAT_PLAN: import.meta.env.VITE_API_PREMIUM_FEAT_PLAN,
  VITE_API_WORKSPACE_PAUSE_GET: import.meta.env.VITE_API_WORKSPACE_PAUSE_GET,
  VITE_ENABLE_AUTH_FORM: import.meta.env.VITE_ENABLE_AUTH_FORM,
  VITE_NGINX_SERVER_NAME: import.meta.env.VITE_NGINX_SERVER_NAME, // keep this as last key just for a clear view as this is for onpremise
  VITE_COMPANY_NAME: import.meta.env.VITE_COMPANY_NAME,
  VITE_FONT_FAMILY_NAME: import.meta.env.VITE_FONT_FAMILY_NAME,
  VITE_BASE_FONT_SIZE: import.meta.env.VITE_BASE_FONT_SIZE,
  VITE_FULL_WHITELABELLED: import.meta.env.VITE_FULL_WHITELABELLED,
  VITE_COMPANY_LOGO_URL: import.meta.env.VITE_COMPANY_LOGO_URL,
  VITE_THEME_PRIMARY1: import.meta.env.VITE_THEME_PRIMARY1,
  VITE_THEME_PRIMARY2: import.meta.env.VITE_THEME_PRIMARY2,
  VITE_THEME_PRIMARY3: import.meta.env.VITE_THEME_PRIMARY3,
  VITE_THEME_PRIMARY4: import.meta.env.VITE_THEME_PRIMARY4,
  VITE_THEME_PRIMARY5: import.meta.env.VITE_THEME_PRIMARY5,
  VITE_THEME_PRIMARY6: import.meta.env.VITE_THEME_PRIMARY6,
  VITE_THEME_PRIMARY7: import.meta.env.VITE_THEME_PRIMARY7,
  VITE_THEME_PRIMARY8: import.meta.env.VITE_THEME_PRIMARY8,
  VITE_THEME_PRIMARY9: import.meta.env.VITE_THEME_PRIMARY9,
  VITE_THEME_FAV_ICON: import.meta.env.VITE_THEME_FAV_ICON,
};

export type RuleEnvironment = Lowercase<keyof typeof Environment>;

export const MAX_POLLING_TIMER = 60000; // value is in milliseconds

export const SQL_COMMANDS = [
  'select',
  'use',
  'replace',
  'update',
  'insert',
  'delete',
  'alter',
  'create',
];

export const POST_REQUESTS = ['POST', 'PUT', 'PATCH'];

export const methods = [
  {
    label: 'GET',
    value: 'GET',
  },
  {
    label: 'POST',
    value: 'POST',
  },
  {
    label: 'PUT',
    value: 'PUT',
  },
  {
    label: 'PATCH',
    value: 'PATCH',
  },
  {
    label: 'DELETE',
    value: 'DELETE',
  },
];

export const bodyParamTypes = [
  {
    label: 'HTML',
    value: 'text/html',
  },
  {
    label: 'Text',
    value: 'text/plain',
  },
  {
    label: 'JavaScript',
    value: 'application/javascript',
  },
  {
    label: 'JSON',
    value: 'application/json',
  },
  {
    label: 'XML',
    value: 'application/xml',
  },
  {
    label: 'FORM',
    value: 'multipart/form-data',
  },
  {
    label: 'FORM URL encoded',
    value: 'application/x-www-form-urlencoded',
  },
];

export const FEATURE_FLAG_API_CONFIG = {
  url: envMap.VITE_API_FEATURE_FLAG,
  payload: {
    environment: 'staging',
    isTest: false,
    params: {
      user: '',
      version: '0.1',
      source: 'fe',
      role: '',
      entity: '',
      wid: '',
      approvalFlowEnabled: false,
    },
  },
};

export const ENTITY_ID = Object.freeze({
  home: 'home',
  rules: 'rules',
  integrations: 'integrations',
  credentials: 'credentials',
  datasets: 'datasets',
  remoteConfig: 'remoteconfig',
  workspace: 'workspace',
  onPremise: 'onpremise',
  auditLogs: 'auditlogs',
  workflow: 'workflow',
  guides: 'guides',
});

export const defaultRoleJson = {
  flags: {
    home: true,
    integrations: true,
    rules: true,
    datasets: true,
    credentials: true,
    remoteconfig: true,
    workspace: true,
    plugin: true,
    auditlogs: true,
    onpremise: true,
    workflow: true,
    guides: true,
  },
  internals: {
    plugin: {
      permissions: {
        read: true,
      },
    },
    integrations: {
      permissions: {
        create: true,
        read: true,
        edit: true,
        delete: true,
        test: true,
        publish: true,
      },
    },
    datasets: {
      permissions: {
        create: true,
        read: true,
        edit: true,
        delete: true,
        test: true,
        viewLive: true,
        publish: true,
        approve: true,
      },
    },
    credentials: {
      subModules: {
        authKeys: {
          enabled: true,
        },
        authConfig: {
          enabled: true,
        },
      },
      permissions: {
        create: true,
        read: true,
        edit: true,
        delete: true,
      },
      limits: {
        maxAuthKeys: {
          value: 10,
          message:
            'You have reached the limit to add credentials. Please upgrade your plan to add more credentials.',
        },
        maxConnectorConfig: {
          value: 50,
          message:
            'You have reached the limit to add keys. Please upgrade your plan to add more config keys.',
        },
      },
    },
    remoteconfig: {
      permissions: {
        create: true,
        read: true,
        edit: true,
        delete: true,
      },
      limits: {
        maxGv: {
          value: 50,
          message:
            'You have reached the limit to add global variables. Please upgrade your plan to add more global variables.',
        },
      },
    },
    rules: {
      subModules: {
        simpleRule: true,
        decisionTable: true,
        ruleSet: true,
      },
      limits: {
        maxCi: {
          value: 50,
          message:
            'You have reached the limit to add input variables. Please upgrade your plan to add more input variables.',
        },
        maxOutputVars: {
          value: 50,
          message:
            'You have reached the limit to add output variables. Please upgrade your plan to add more output variables.',
        },
        maxActions: {
          value: 10,
          message:
            'You have reached the limit to add actions. Please upgrade your plan to add more actions.',
        },
        maxSrRs: {
          value: 50,
          message:
            'You have reached the limit to add simple rules in rulesets. Please upgrade your plan to add more rulesets.',
        },
      },
      permissions: {
        create: true,
        read: true,
        edit: true,
        delete: true,
        clone: true,
        test: true,
        viewLive: true,
        publish: true,
        share: true,
        discard: true,
        approve: true,
      },
    },
    workspace: {
      subModules: {
        settings: {
          enabled: true,
        },
        invite: {
          enabled: true,
        },
        embed: {
          enabled: true,
        },
      },
      permissions: {
        read: true,
        create: true,
        edit: true,
        delete: true,
        invite: true,
        roleRevoke: true,
        roleUpdate: true,
      },
    },
    workflow: {
      permissions: {
        create: true,
        read: true,
        edit: true,
        delete: true,
        clone: true,
        test: true,
        viewLive: true,
        publish: true,
        share: true,
        discard: true,
        approve: true,
      },
    },
    auditlogs: {
      permissions: {
        read: true,
      },
    },
    onpremise: {
      permissions: {
        read: true,
      },
    },
  },
};

export const defaultAccessMap = {
  admin: defaultRoleJson,
  owner: defaultRoleJson,
  approver: defaultRoleJson,
  editor: defaultRoleJson,
  viewer: defaultRoleJson,
  restricted: defaultRoleJson,
  tech: defaultRoleJson,
};

export const editEntityAccessDisableRoles: Role[] = ['tech', 'viewer'];

export const hideOptionalCustomAttributes =
  envMap.VITE_HIDE_OPTIONAL_ATTRIBUTES === 'true';

export const demoRuleCreated = 'demo_rule_created';
export const demoWorkflowCreated = 'demo_workflow_created';
export const generateApiKey = 'generate_api_key';
export const newRuleCreated = 'new_rule_created';
export const userLoggedIn = 'user_logged_in';
export const userPlanCreated = 'user_plan_created';
export const attachedDatabaseRule = 'dataset_added';
export const integrationsAdded = 'integrations_added';
export const actionsAdded = 'actions_added';
export const onboardingCompleted = 'onboarding_completed';
export const addCustomAttributes = 'add_custom_attributes';
export const addConditionAndResult = 'add_conditions_and_result';
export const testAndPublishRule = 'test_and_publish_rule';
export const configureApiScheduler = 'configure_api_scheduler';
export const configureDatabase =
  'configure_data_source_query_in_case_of_database';
export const mapDatasource = 'map_data_source_inside_add_input_attributes';
export const addAttributesForFetchAPI = 'add_attributes_under_fetch_over_api';
export const defineTriggerAndCI = 'define_trigger_and_custom_input_attributes';
export const addActionWorkflow = 'add_action_workflow';
export const addResponseNode = 'add_response_vis_response_node';
export const testAndPublishWorkflow = 'test_and_publish_workflow';
export const addDelayNode = 'try_control_nodes_delay';
export const inviteUser = 'collaborate_with_larger_group';
export const useJSCode = 'inbuilt_custom_code';
export const exploreGlobalAttributes = 'explore_global_attributes';
export const exploreOnPremise = 'on_premise_script_to_self_host';

export const LISTING_PAGE_SIZE = 10;
export const ENTITY_MAP: Record<string, string> = {
  auditlogs: 'audit',
  rules: 'rules',
  datasets: 'datasets',
};

export const HelpMenu = {
  title: 'Help',
  items: [
    {
      name: 'Documentation',
      icon: `${
        envMap.VITE_ASSETS_URL ?? ''
      }website/icons/documentationMenu.svg`,
      alt: 'Documentation',
      route: 'https://docs.nected.ai/',
      target: '_blank',
    },
    {
      name: 'Video Guides',
      icon: `${envMap.VITE_ASSETS_URL ?? ''}website/icons/play_grey.svg`,
      alt: 'Video Guides',
      route: '/guides',
      target: '_self',
    },
  ],
};

export const TokenScores: Record<string, number> = {
  additionalData: 999,
  customInput: 999,
  globalVar: 999,
  systemVar: 999,
  dataSet: 999,
  outputData: 999,
  outputDataList: 999,
};

export const TokenDataTypeByDataType: Record<string, string> = {
  date: 'dates',
  dateTime: 'dateTime',
  numeric: 'numeric',
  string: 'strings',
  boolean: 'boolean',
  jsFormula: 'jsFormula',
  excelFormula: 'excelFormula',
  json: 'objects',
  list: 'list',
  array: 'list',
};

export const CRON_UNITS = [
  {
    label: 'Minutes',
    value: 'minute',
  },
  {
    label: 'Hours',
    value: 'hourly',
  },
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Cron',
    value: 'cron',
  },
];

export const defaultSchedulerValues = {
  startAt: undefined,
  endAt: undefined,
  cron: {
    days: '*',
    hours: '*',
    minutes: '*',
    month: '*',
    weekdays: '*',
  },
  days: [],
  hours: 1,
  minutes: 1,
  weekdays: [],
  unit: CRON_UNITS[0],
};

export const TokenDataTypeByDataTypeSingle: Record<string, string> = {
  date: 'dateString',
  dateTime: 'dateTimeString',
  numeric: 'numeric',
  string: 'string',
  boolean: 'boolean',
  jsFormula: 'jsFormula',
  json: 'object',
  list: 'list',
  aray: 'list',
};

export const CronInitialData: RuleCronScheduleModel = {
  startAt: '',
  endAt: '',
  inputParam: {},
  isEnabled: true,
  spec: {
    dayOfMonth: '',
    dayOfWeek: '',
    hour: '',
    minute: '',
    month: '',
  },
  unit: '',
};

export const editorDomain: string | undefined = envMap.VITE_EDITOR_URL;

export const emptyQuery = gql`
  query emptyQuery {
    data
  }
`;

export const emptyMutationQuery = gql`
  mutation enptyQuery {
    data
  }
`;

export const toCamelCase = (inputString: string) => {
  return inputString
    .replace(CAMEL_CASE_REGEX, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(SPACE_REGEX, '');
};

export const DELAY_OPTION_LIST: DelayNodeFormAction[] = [
  {
    label: 'Immediately',
    value: 'immed',
    desc: 'Add meta text for this option',
  },
  {
    label: 'After Time Interval',
    value: 'ATI',
    desc: 'Waits for a set amount of time',
  },
  {
    label: 'At Specific Time',
    value: 'AST',
    desc: 'Waits for until a certain date or time',
  },
];

export const INTERVAL_OPTION_LIST = [
  {
    label: 'Minutes',
    value: 'm',
  },
  {
    label: 'Hours',
    value: 'h',
  },
  {
    label: 'Days',
    value: 'd',
  },
  {
    label: 'Weeks',
    value: 'w',
  },
];

export const TESTABLE_NODES = [
  'dbNode',
  'codeNode',
  'responseNode',
  'setVarNode',
  'rule',
  'workflowNode',
  'gSheetNode',
];

export const sourceTypesToIgnoreOnValidation = [
  'systemVar',
  'nectedExecutionInfo',
];
export const sourceTypesToIgnoreOnValueReplacement = ['systemVar', 'globalVar'];
export const datatypesToIgnoreOnValidation = ['generic'];
export const DIFFERENT_TABS_V2: ControlItemsType[] = [
  {
    header: 'Select Type',
    currentTab: 0,
  },
  {
    header: 'Select Connector',
    currentTab: 3,
    backTo: 0,
  },
];

export const SCHEMA_NOT_TO_BE_FETCHED_FOR = ['gsheet'];

export const NODE_SAVING_TIME = 3000;

export const LIST_OF_LANGUAGES_FOR_CODE = [
  {
    label: 'JavaScript',
    value: 'JS',
  },
  {
    label: 'Formula',
    value: 'excelFormula',
  },
];

export const MAX_NUMBER_OF_CONDITIONS_SWITCHER = 10;

export const MAX_NUMBER_OF_PATHS_SWITCHER = 10;

export const COMMON_NODE_STYLES = {
  background: 'var(--color-primary1)',
  height: 8,
  width: 8,
  border: '1px solid var(--color-primary1)',
};

export const GSHEET_MAPPING_METHODS = [
  {
    label: 'Mapping Automatically',
    value: 'automatic',
    desc: 'The columns in the recipient sheet should match with the donor sheet',
  },
  {
    label: 'Map each value manually',
    value: 'manual',
    desc: 'You will have to map the the columns manually',
  },
];

export const GSHEET_UPDATING_METHODS = [
  {
    label: 'Update all the matching records',
    value: 'all',
    desc: 'Subtext of multi record update flag',
  },
  {
    label: 'Update the first matching from top',
    value: 'first',
    desc: 'Subtext of single record update flag',
  },
];

export const GSHEET_ACTION_METHODS = [
  {
    label: 'Update Row(s)',
    value: 'update',
  },
  {
    label: 'Insert Row(s)',
    value: 'insert',
  },
  {
    label: 'Look-up',
    value: 'lookup',
  },
];

export const GSHEET_HEADERS_SELECTION = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export const PAGE_SIZE = 10;

export const WORKSPACE_THEME_FONT_FAMILY = [
  {
    label: 'Lato',
    value: 'Lato',
  },
  {
    label: 'Poppins',
    value: 'Poppins',
  },
  {
    label: 'Montserrat',
    value: 'Montserrat',
  },
  {
    label: 'Oswald',
    value: 'Oswald',
  },
  {
    label: 'Work Sans',
    value: 'Work Sans',
  },
];

const computeEnvValue = (
  envVariable: string,
  defaultValue: any,
  type: string = ''
) => {
  let value = envVariable;

  if (type === 'color' && !value.includes('#')) {
    value = `#${value}`;
  }

  return _isEmpty(envVariable) || _isNil(envVariable) ? defaultValue : value;
};

export const WORKSPACE_BASIC_THEME = {
  title: computeEnvValue(envMap.VITE_COMPANY_NAME, 'Nected'),
  // eslint-disable-next-line
  logoUrl: computeEnvValue(
    envMap.VITE_COMPANY_LOGO_URL,
    '/assets/konark/images/logo.svg'
  ),
  favicon: computeEnvValue(envMap.VITE_THEME_FAV_ICON, '/favicon.ico'),
  baseFontFamily: {
    label: computeEnvValue(envMap.VITE_FONT_FAMILY_NAME, 'Lato'),
    value: computeEnvValue(envMap.VITE_FONT_FAMILY_NAME, 'Lato'),
  },
  baseFontSize: computeEnvValue(envMap.VITE_BASE_FONT_SIZE, 10),
  colors: {
    primary: [
      {
        name: 'Primary Color',
        key: 'primary1',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY1, '#2D7CFF', 'color'),
      },
      {
        name: 'Secondary Color',
        key: 'primary2',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY2, '#E3EDFF', 'color'),
      },

      {
        name: 'Background Color',
        key: 'primary3',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY3, '#F4F7FF', 'color'),
      },
      {
        name: 'Dropdown Color',
        key: 'primary4',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY4, '#F9FBFF', 'color'),
      },
      {
        name: 'Profile Picture Border',
        key: 'primary5',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY5, '#0052CC', 'color'),
      },
      {
        name: 'Alert Information Color',
        key: 'primary6',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY6, '#0F4296', 'color'),
      },
      {
        name: 'Action Count Text',
        key: 'primary7',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY7, '#C7DCFF', 'color'),
      },
      {
        name: 'Hover State Color',
        key: 'primary8',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY8, '#D2DEF6', 'color'),
      },
      {
        name: 'Upgrade State Color',
        key: 'primary9',
        // eslint-disable-next-line
        value: computeEnvValue(envMap.VITE_THEME_PRIMARY9, '#4984ff', 'color'),
      },
    ],
  },
};

export const workspaceBasicThemeFormat = (
  workspaceValue: Record<string, any>
) => {
  const updatedValue: Record<string, any> = {};

  Object.keys(workspaceValue).forEach((key) => {
    if (key !== 'baseFontFamily') {
      updatedValue[key] = workspaceValue[key];
    } else {
      updatedValue[key] = workspaceValue[key]?.value ?? 'Lato';
    }
  });

  return updatedValue;
};

export const TOOLTIP_COLORS_BY_KEY: Record<string, any> = {
  primary1: 'Controls the main branding and key visual elements.',
  primary2:
    'Supports the primary color, used for accents or secondary highlights.',
  primary3:
    'Changes the color of empty spaces, such as the background of the box containing the upload file button.',
  primary4: 'Controls the color of dropdown menus in version control.',
  primary5: 'Changes the color around the display picture on the platform.',
  primary6:
    'Used for informational messages (e.g., “Changes in settings will be live when the rule is published to production”).',
  primary7:
    'Sets the color of the text showing action counts after testing a rule.',
  primary8: 'Changes the color of hover effects in version control.',
  primary9: 'Controls the color of elements indicating an upgrade state.',
};
